<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="1. 명칭 및 유해성・위험성" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-dept
                  :required="true"
                  :editable="editable"
                  type="edit"
                  label="부서"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  disabled
                  :editable="editable"
                  label="물질명"
                  name="materialName"
                  v-model="data.materialName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <c-text
                  :editable="editable"
                  label="구성성분"
                  name="substanceName"
                  v-model="data.substanceName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  label="신호어"
                  name="signName"
                  v-model="data.signName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <c-textarea
                  :editable="editable"
                  label="유해, 위험성 분류"
                  name="riskType"
                  :rows="3"
                  v-model="data.riskType">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <c-textarea
                  :editable="editable"
                  label="유해, 위험 문구"
                  name="riskWork"
                  :rows="3"
                  v-model="data.riskWork">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="그림문자" class="cardClassDetailForm msdsc pmsds" :collapsed="true">
            <template slot="card-detail">
              <!-- <div class="col-12"> -->
                <q-intersection
                  v-for="(item, index) in imgList"
                  :key="index"
                  class="example-item"
                  style="display:inline;"
                >
                  <img :src="require('@/assets/images/'+item.imgname+'.gif')"
                    :style="{ width: '90px', }" :class="[!item.chk ? 'noselectImg' : '']">
                  <q-checkbox v-model="item.chk" color="red" />
                </q-intersection>
              <!-- </div> -->
            </template>
          </c-card>
          <c-card title="2. 취급 시 주의사항" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="가. 안전 취급 요령"
                  name="twoFirst"
                  :rows="5"
                  v-model="data.twoFirst">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="나. 안전한 저장 방법"
                  name="twoSecond"
                  :rows="5"
                  v-model="data.twoSecond">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="3. 적정 보호구" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="가. 호흡기 보호"
                  name="threeFirst"
                  :rows="3"
                  v-model="data.threeFirst">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="나. 눈 보호"
                  name="threeSecond"
                  :rows="3"
                  v-model="data.threeSecond">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="다. 손 보호"
                  name="threeThird"
                  :rows="3"
                  v-model="data.threeThird">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="라. 신체 보호"
                  name="threeFourth"
                  :rows="3"
                  v-model="data.threeFourth">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="4. 응급조치요령" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="가. 눈에 들어갔을 때"
                  name="fourFirst"
                  :rows="5"
                  v-model="data.fourFirst">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="나. 피부에 접촉했을 때"
                  name="fourSecond"
                  :rows="5"
                  v-model="data.fourSecond">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="다. 흡입했을 때"
                  name="fourThird"
                  :rows="5"
                  v-model="data.fourThird">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="라. 먹었을 때"
                  name="fourFourth"
                  :rows="5"
                  v-model="data.fourFourth">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="마. 기타"
                  name="fourFifth"
                  :rows="5"
                  v-model="data.fourFifth">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="5. 누출 및 폭발·화재 사고시 대처방법" class="cardClassDetailForm msdsc" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="가. 적절한 소화제"
                  name="fiveFirst"
                  :rows="5"
                  v-model="data.fiveFirst">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="나. 화학물질로부터 생기는 특정 유해성"
                  name="fiveSecond"
                  :rows="5"
                  v-model="data.fiveSecond">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  label="다. 화재진압 시 착용할 보호구 및 예방조치"
                  name="fiveThird"
                  :rows="5"
                  v-model="data.fiveThird">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="data"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
            <c-btn 
              v-if="data.materialKnowhowId && editable" 
              flat 
              label="삭제" 
              icon="delete_forever" 
              @btnClicked="removeData" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemProcess-knowhow-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
  },
  data() {
    return {
      imgList: [
        {
          imgname: 'GHS01',
          chk: false,
        },
        {
          imgname: 'GHS02',
          chk: false,
        },
        {
          imgname: 'GHS03',
          chk: false,
        },
        {
          imgname: 'GHS04',
          chk: false,
        },
        {
          imgname: 'GHS05',
          chk: false,
        },
        {
          imgname: 'GHS06',
          chk: false,
        },
        {
          imgname: 'GHS07',
          chk: false,
        },
        {
          imgname: 'GHS08',
          chk: false,
        },
        {
          imgname: 'GHS09',
          chk: false,
        },
      ],
      editable: true,
      saveUrl: transactionConfig.chm.knowhow.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      isdelete: false,
      data: {
        mdmChemMaterialId: '',
        materialKnowhowId: '',  // 화학물질 작업공정별 관리요청 일련번호
        materialName: '',  // 물질명
        processName: '',  // 작업공정
        deptCd: '',  // 부서
        substanceName: '',  // 구성성분
        signName: '',  // 신호어
        riskType: '',  // 유해위험성 분류
        riskWork: '',  // 유해위험 문구
        imageWord: '',  // 그림문자
        twoFirst: '',  // 가. 안전 취급 요령
        twoSecond: '',  // 나. 안전한 저장 방법
        threeFirst: '',  // 가. 호흡기 보호
        threeSecond: '',  // 나. 눈 보호
        threeThird: '',  // 다. 손 보호
        threeFourth: '',  // 라. 신체 보호
        fourFirst: '',  // 가. 눈에 들어갔을 때
        fourSecond: '',  // 나. 피부에 접촉했을 때
        fourThird: '',  // 다. 흡입했을 때
        fourFourth: '',  // 라. 먹었을 때
        fourFifth: '',  // 마. 기타
        fiveFirst: '',  // 가. 적절한 소화제
        fiveSecond: '',  // 나. 화학물질로부터 생기는 특정 유해성
        fiveThird: '',  // 다. 화재진압 시 착용할 보호구 및 예방조치
        useFlag: 'Y',  // 사용여부
      },
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.chm.knowhow.get.url;
      this.printUrl = selectConfig.chm.knowhow.print.url;
      this.insertUrl = transactionConfig.chm.knowhow.insert.url;
      this.updateUrl = transactionConfig.chm.knowhow.update.url;
      this.deleteUrl = transactionConfig.chm.knowhow.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmChemMaterialId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId};
        this.$http.request((_result) => {
          this.data = _result.data;
          if (_result.data.imageWord) {
            let imagewords = _result.data.imageWord.split(',');
            if (imagewords.length > 0) {
              this.$_.forEach(this.imgList, item => {
                this.$_.forEach(imagewords, _img => {
                  if (item.imgname == _img) {
                    item.chk = true;
                  }
                });
              });
            }
          } else {
            this.$_.forEach(this.imgList, item => {
              item.chk = false;
            });
          }
        },);
      } else {
        this.$set(this.data, 'materialName', this.popupParam.materialName)
      }
    },
    saveData() {
      if (this.data.materialKnowhowId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              let imagewords = [];
              this.$_.forEach(this.imgList, item => {
                if (item.chk) {
                  imagewords.push(item.imgname);
                }
              });
              this.data.imageWord = imagewords.join(',');

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.materialKnowhowId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.mdmChemMaterialId = result.data.mdmChemMaterialId
      this.getDetail();
    },
    printChem() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.data.materialKnowhowId);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.data.materialName + ' 요약본' + ".docx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
<style lang="sass">
.msdsc
  margin-top: 10px
.pmsds > .cardheader
  padding-top: 0px !important
  padding-bottom: 10px !important
.example-item .q-checkbox .q-checkbox__inner
  margin: auto !important
.example-item .inline
    display: block !important
.noselectImg
  opacity: 0.2
</style>